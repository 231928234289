import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProjectList from "../components/project-list"

const components = {
  p: props => <p className="mb-4" {...props} />,
  h1: props => <h1 className="text-4xl font-bold mb-6" {...props} />,
  h2: props => <h2 className="text-3xl font-semibold mb-4" {...props} />,
  h3: props => <h3 className="text-2xl font-medium mb-3" {...props} />,
  ul: props => <ul {...props} />,
  ol: props => <ol {...props} />,
  li: props => <li {...props} />,
  img: props => (
    <img
      {...props}
      className="my-8 rounded-lg mx-auto"
      alt={props.alt || ""}
    />
  ),
  GatsbyImage: props => <GatsbyImage {...props} />,
  getImage: props => getImage(props),
}

const ProjectTemplate = ({ data, pageContext, location }) => {
  const project = data.mdx
  const otherProjects = data.allMdx.nodes.filter(
    p => pageContext.slug !== p.fields.slug
  )
  const siteTitle = data.site.siteMetadata.title

  const bannerImage = project.frontmatter.bannerImage
  const banner = bannerImage.childImageSharp ? getImage(bannerImage) : null

  return (
    <Layout location={location} title={siteTitle}>
      {banner ? (
        <GatsbyImage
          image={banner}
          alt={project.frontmatter.title}
          className="rounded-t-2xl"
        />
      ) : (
        <img
          src={bannerImage.publicURL}
          alt={project.frontmatter.title}
          className="w-full rounded-t-2xl"
        />
      )}
      <div>
        <Seo title={project.frontmatter.title} />
        <article className="mb-24 md:mb-40">
          <header className="mt-16 mx-0 md:mx-14 lg:mx-28">
            <h1 className="text-3xl sm:text-4xl md:text-5xl text-black opacity-90 leading-normal sm:leading-normal md:leading-normal mb-8">
              {project.frontmatter.title}
            </h1>
            <small className="text-xl text-black opacity-50 block mb-16">
              {project.frontmatter.subtitle}
            </small>
            <div className="w-full h-px bg-black opacity-10"></div>
          </header>
          <MDXProvider components={components}>
            <div className="prose md:prose-lg max-w-none mt-16 mx-0 md:mx-14 lg:mx-28 overflow-visible">
              <MDXRenderer localImages={project.frontmatter.embeddedImagesLocal}>
                {project.body}
              </MDXRenderer>
            </div>
          </MDXProvider>
          <footer></footer>
        </article>
        {/*<div className="mt-16 md:mt-24">
        <h2 className="text-black opacity-90 font-semibold text-2xl md:text-4xl">
            More projects
          </h2>
          <ProjectList projects={otherProjects} />
          
        </div>*/}
      </div>
    </Layout>
  )
}

export default ProjectTemplate

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        title
        subtitle
        bannerImage {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, width: 2400, quality: 100)
          }
        }
      }
    }
    allMdx {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          subtitle
          coverImage {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 400, height: 300)
            }
          }
        }
      }
    }
  }
`
